import { helpers } from "@vuelidate/validators";
export const isPhoneValid = (phone) => (!helpers.req(phone)) || (phone.number === undefined) || (phone.isValid === true)

export default {
  inject: ['locale'],
  computed: {
    requiredLabel() {
      return this.locale === 'es' ? 'Campo requerido' : 'Required field';
    },
    phoneValidLabel() {
      return this.locale === 'es' ? 'Número inválido' : 'Invalid number';
    },
    passwordFormatLabel() {
      return this.locale === 'es' ? 'Mínimo 6 caracteres' : 'Minimum 6 characters';
    },
    passwordsMatchLabel() {
      return this.locale === 'es' ? 'Las contraseñas no coinciden' : 'Passwords do not match';
    },
    formatLabel() {
      return this.locale === 'es' ? 'Formato incorrecto' : 'Incorrect format';
    }
  }
}